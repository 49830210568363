import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroPages from "../components/heroPages"
import Image from "../components/imagesComponent/alerts"
import { graphql } from "gatsby"

export default function Coronavirus({ data = {} }) {
  const { barText, title, subtitle, contentHtml } = data.takeshape.getTopBar
  const footer = data.takeshape.getContactUsPage
  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="Coronavirus" />
      <HeroPages title={title} text={subtitle} image={<Image />} />
      <div className="container mx-auto px-5 md:mt-16 md:mb-24 mb-10 leading-loose">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: contentHtml }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getTopBar(locale: $locale) {
        barText
        title
        contentHtml
        subtitle
      }
      getContactUsPage {
        phones {
          phone
        }
        facebook
        instagram
      }
    }
  }
`
